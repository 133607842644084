import { Formik } from 'formik';
import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import * as Reaptcha from 'reaptcha';
import styled, { css } from 'styled-components';
import GlobalStyle from '../assets/styles/GlobalStyle';
import { BtnTypes } from '../assets/styles/Utils';
import { COLORS } from '../assets/styles/variables';
import Button from '../atoms/Button';
import FormInput from '../atoms/FormInput';
import Text from '../atoms/Text';
import { contactReasons, ENV_VARS, URLS } from '../config/constants';
import Layout, { MyContext } from '../layout/LayoutContainer';
import '../locales/i18n';
import ButtonRow from '../molecules/ButtonRow';
import CTABanner from '../organisms/CTABanner/CTABanner';
import CTABannerMainText from '../organisms/CTABanner/CTABannerMainText';
import Footer, { MobileFooter } from '../organisms/Footer/Footer';
import Header from '../organisms/Header';
import SectionHeader from '../organisms/SectionHeader/SectionHeader';
import SectionHeaderMainText from '../organisms/SectionHeader/SectionHeaderMainText';
import SectionHeaderSubText from '../organisms/SectionHeader/SectionHeaderSubText';
import { mobile } from '../utils/media';

const ContactFormBgWrapper = styled.div`
  background-color: ${COLORS.sectionBg};
  padding: 40px 10%;
  text-align: center;

  @media only screen and (max-width: 1350px) {
    padding: 40px 3%;
  }

  ${mobile(css`
    padding: 15px;
  `)}
`;

const HelpLink = styled.a`
  color: ${COLORS.link};
  text-decoration: underline;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const ContactFormWrapper = styled.div`
  display: flex;
  padding: 30px 0 0;
  flex-wrap: wrap;
  width: 700px;
  margin: auto;
  position: relative;
  z-index: 100;

  textarea {
    width: 100%;
    flex-basis: 100%;
    min-height: 200px;
    padding: 15px;
    border-radius: 5px;
    -webkit-appearance: none;
  }

  ${InputWrapper} {
    margin-bottom: 30px;
    flex-basis: 49%;

    &:nth-child(odd) {
      margin-right: 2%;
    }

    &.full-row {
      flex-basis: 100%;
      margin: 0;
    }
    ${mobile(css`
      width: 100%;
      flex-basis: 98%;
    `)}
  }
  ${mobile(css`
    width: 100%;
  `)}
`;

const LastRow = styled.div`
  width: 100%;
  flex-basis: 100% !important;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: end;
  align-items: flex-end;

  .container {
    display: block;
    position: relative;
    line-height: 2.2;
    padding-left: 45px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
  }

  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background-color: ${COLORS.txtLight};
    border: 1px solid ${COLORS.border};
    border-radius: 5px;
  }

  .container:hover input ~ .checkmark {
    background-color: ${COLORS.sectionBg};
  }

  .container input:checked ~ .checkmark {
    background-color: ${COLORS.brand};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 10px;
    top: 8px;
    width: 7px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

const ReasonsSelect = styled.select`
  padding: 15px;
  display: inline-block;
  background-color: white;
  border-radius: 5px;
  border: 1px solid ${COLORS.border};
  box-shadow: none;
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  outline: 0;

  &.minimal {
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 20px) calc(1em + 8px),calc(100% - 15px) calc(1em + 8px),calc(100% - 2.5em) 0.5em;
    background-size: 7px 5px,5px 5px,1px 2.3em;
    background-repeat: no-repeat;
}
  }

  &.minimal:focus {
    background-image: linear-gradient(45deg, #ccc 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #ccc 50%), linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1.5em,calc(100% - 20px) 1.5em,calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px,5px 5px,1px 2.3em;
    background-repeat: no-repeat;
    outline: 0;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 10px;
`;

const ErrorMessage = styled(Text)`
  position: absolute;
  left: 0;
  bottom: -20px;
  margin-left: 15px;
`;

const CTABannerMobile = styled(CTABanner)`
  ${mobile(css`
    height: 400px;
    max-height: 400px;
    min-height: 350px;
  `)}
`;
class ContactUsPage extends React.Component {
  onTapHelp = () => window.open(URLS.helpPortal, '_BLANK');
  onLogin = () => window.open(URLS.login, '_BLANK');
  onPlatformSignup = () => window.open(URLS.signup, '_BLANK');

  constructor(props) {
    super(props);
    this.captcha = null;
  }

  state = {
    recaptcha: '',
    formValues: {},
    emailSent: false,
    emailError: false,
  };

  executeCaptcha = () => {
    this.captcha.execute();
  };

  onVerify = (res) => {
    this.setState({ recaptcha: res, emailSent: false, emailError: false });
    this.sendEmail();
  };

  sendEmail = () => {
    const values = this.state.formValues;
    const recaptcha = this.state.recaptcha;
    const language = localStorage.getItem('i18nextLng');
    return fetch(`${URLS.profileManagerUrl}public/contacts`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ ...values, language, 'g-recaptcha-response': recaptcha }),
    })
      .then((res) => {
        return this.setState({
          formValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            companyName: '',
            reason: -1,
            message: '',
            privacyPolicy: false,
          },
          emailSent: true,
        });
      })
      .catch((err) => this.sendEmail({ emailError: true }));
  };

  render() {
    const { t } = this.props;
    const fromPersona = this.props.location.state && this.props.location.state.persona;

    const contactReasonsOptions = contactReasons.map((option) => (
      <option key={option.value} value={option.value}>
        {t(option.labelKey)}
      </option>
    ));

    const HeaderCon = (props) => {
      return <MyContext.Consumer>{(value) => <Header {...value} {...props} />}</MyContext.Consumer>;
    };

    return (
      <Layout {...this.props}>
        <Reaptcha
          ref={(e) => (this.captcha = e)}
          sitekey={ENV_VARS.recaptchaKey}
          size={'invisible'}
          onVerify={this.onVerify}
        />
        <HeaderCon loginCb={this.onLogin} />
        <CTABannerMobile height={640} bgImage={`contact-us/contactus.png`}>
          <div>
            <CTABannerMainText block>{t(`app:contact-us:cta:title`)}</CTABannerMainText>
          </div>
        </CTABannerMobile>
        <SectionHeader>
          <SectionHeaderMainText>{t('app:contact-us:here_to_help')}</SectionHeaderMainText>
          <SectionHeaderSubText>
            <Trans i18nKey={'app:contact-us:here_to_help_subtext'}>
              <Text weight={'bold'} block>
                0
              </Text>
              <Text>1</Text>
            </Trans>
          </SectionHeaderSubText>
        </SectionHeader>
        <ContactFormBgWrapper>
          <Text size={'35px'} block lineHeight={3} transform={'uppercase'}>
            {t('app:contact-us:contact_us_form_title')}
          </Text>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              companyName: '',
              reason: -1,
              message: fromPersona
                ? t('app:contact-us:form:prefilled_message', { personas: fromPersona.toLocaleLowerCase() })
                : '',
              privacyPolicy: false,
            }}
            validate={(values) => {
              let errors = {};
              Object.keys(values).forEach((key) => {
                if (!values[key]) {
                  errors[key] = t('app:contact-us:form:required_field');
                }
              });
              if (!errors.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = t('app:contact-us:form:email_not_valid');
              }
              if (values.reason && parseInt(values.reason) < 0) {
                errors.reason = t('app:contact-us:form:required_field');
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              this.setState({ formValues: values });
              const checkboxEl = document.getElementById('privacyPolicyCheckbox');
              checkboxEl.checked = false;
              resetForm();
              setSubmitting(false);
            }}
          >
            {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
              const formHasBeenTouched = Object.values(touched).length > 0;
              const formHasErrors = errors && Object.values(errors) && Object.values(errors).join('').length > 0;
              return (
                <form id={'contact-form'} onSubmit={handleSubmit}>
                  <ContactFormWrapper>
                    <InputWrapper>
                      <FormInput
                        required
                        name={'firstName'}
                        placeholder={t('app:contact-us:form:first_name')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.firstName}
                      />
                      {touched.firstName && errors.firstName && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.firstName}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        required
                        name={'lastName'}
                        placeholder={t('app:contact-us:form:last_name')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.lastName}
                      />
                      {touched.lastName && errors.lastName && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.lastName}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        required
                        name={'email'}
                        type={'email'}
                        placeholder={t('app:contact-us:form:email')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      {touched.email && errors.email && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.email}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        required
                        name={'phone'}
                        placeholder={t('app:contact-us:form:phone')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.phone}
                      />
                      {touched.phone && errors.phone && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.phone}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <FormInput
                        required
                        name={'companyName'}
                        placeholder={t('app:contact-us:form:company')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.companyName}
                      />
                      {touched.companyName && errors.companyName && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.companyName}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper>
                      <ReasonsSelect
                        className="minimal"
                        required
                        name={'reason'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.reason}
                      >
                        <option value="-1">{t('app:contact-us:form:reason')} *</option>
                        {contactReasonsOptions}
                      </ReasonsSelect>
                      {touched.reason && errors.reason && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.reason}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <InputWrapper className={'full-row'}>
                      <textarea
                        name={'message'}
                        placeholder={t('app:contact-us:form:message')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.message}
                      />
                      {touched.message && errors.message && (
                        <ErrorMessage color={COLORS.txtError} size={'12px'}>
                          {errors.message}
                        </ErrorMessage>
                      )}
                    </InputWrapper>
                    <span style={{ padding: '20px 5px', textAlign: 'left' }}>
                      <label className="container">
                        <input
                          type={'checkbox'}
                          id={'privacyPolicyCheckbox'}
                          name={'privacyPolicy'}
                          value={values.privacyPolicy}
                          onChange={handleChange}
                          style={{ padding: '20px' }}
                        />
                        <span className="checkmark" style={{ padding: '10px' }} />
                        <Trans i18nKey={'app:contact-us:form:accept_privacy'}>
                          <HelpLink href={URLS.privacyUrl} target={'_BLANK'} />
                        </Trans>
                        &nbsp;*
                      </label>
                    </span>
                    <LastRow>
                      <Text block color={COLORS.shadow} size={'12px'} lineHeight={3}>
                        {t('app:contact-us:form:required')}
                      </Text>
                      <SubmitButton
                        role={BtnTypes.primary}
                        //                        type={'submit'}
                        big
                        onClick={this.executeCaptcha}
                        disabled={!formHasBeenTouched || formHasErrors || isSubmitting}
                      >
                        {t('app:contact-us:form:submit_btn_label')}
                      </SubmitButton>
                      <br />
                      {this.state.emailSent && (
                        <Text color={COLORS.brand} lineHeight={2} size={'20px'} weight={'bold'}>
                          {t('app:contact-us:form:email_sent_success')}
                        </Text>
                      )}
                      {this.state.emailError && (
                        <Text color={COLORS.txtError} lineHeight={2} size={'20px'} weight={'bold'}>
                          {t('app:contact-us:form:email_sent_error')}
                        </Text>
                      )}
                    </LastRow>
                  </ContactFormWrapper>
                </form>
              );
            }}
          </Formik>
        </ContactFormBgWrapper>
        <ButtonRow buttonLabel={t('app:get_help_btn')} buttonCb={this.onTapHelp} bgColor={COLORS.agricultureGreen} />
        <Footer />
        <MobileFooter />
        <GlobalStyle />
      </Layout>
    );
  }
}

ContactUsPage.propTypes = {};

export default withTranslation()(ContactUsPage);
