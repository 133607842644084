import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { CenteredRowGrid } from '../assets/styles/Utils';
import Button from '../atoms/Button';
import { mobile } from '../utils/media';

const ButtonRowEl = styled(CenteredRowGrid)`
  margin: 70px 0;
  ${mobile(css`
    margin: 50px 30px;
  `)}
`;

const ButtonRow = ({ buttonCb, buttonLabel, bgColor }) => {
  return (
    <ButtonRowEl>
      <Button big onClick={buttonCb} bgColor={bgColor}>
        {buttonLabel}
      </Button>
    </ButtonRowEl>
  );
};

ButtonRow.propTypes = {
  buttonCb: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
};

export default ButtonRow;
